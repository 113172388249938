import React from 'react'
import { css } from '@emotion/react'

import { Text } from './design'

export const h1: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h1" {...props} />
export const h2: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h2" {...props} />
export const h3: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h3" {...props} />
export const h4: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h4" {...props} />
export const h5: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h5" {...props} />
export const h6: React.FC<React.ComponentProps<typeof Text>> = props => <Text use="h6" {...props} />

export const p: React.FC<React.ComponentProps<typeof Text>> = props => (
  <Text
    use="p"
    css={css`
      & + & {
        margin-top: 1.5em;
      }
    `}
    {...props}
  />
)
