import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Location, HistoryLocation } from '@reach/router'

export const LocationContext = React.createContext<HistoryLocation | null>(null)

export const LocationProvider = ({ children }: { children: React.ReactNode }) => (
  <Location>
    {({ location }) => (
      <LocationContext.Provider value={location}>{children}</LocationContext.Provider>
    )}
  </Location>
)

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useLocation = () => useContext(LocationContext)

export default useLocation
