import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'

const List = ({ items }) => (
  <ul>
    {items.map(item => (
      <li key={item.path}>
        <Link to={item.path}>{item.name}</Link>
        {item.children && <List items={item.children} />}
      </li>
    ))}
  </ul>
)

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
}

List.defaultProps = {}

const Navigation = ({ navigation: { children } }) => (
  <nav id="nav">
    <List items={children} />
  </nav>
)

Navigation.propTypes = {
  navigation: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
}

Navigation.defaultProps = {}

const query = graphql`
  query {
    navigation: globalNavigationItem {
      path
      name
      children: childrenGlobalNavigationItem {
        path
        name
        children: childrenGlobalNavigationItem {
          path
          name
          children: childrenGlobalNavigationItem {
            path
            name
          }
        }
      }
    }
  }
`

const NavigationProvider = props => (
  <StaticQuery query={query} render={data => <Navigation {...data} {...props} />} />
)

export default NavigationProvider
