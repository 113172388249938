import React from 'react'
import Helmet from 'react-helmet'
import css from '@styled-system/css'

import { useSiteMetadata } from 'queries'
import GlobalProviders from 'components/GlobalProviders'
import Footer from 'components/Footer'
import PrimaryNavigation from './components/PrimaryNavigation'

const HomeHeader = ({ heightAdjust: heightAdjust }: { heightAdjust?: string | number }) => {
  return (
    <div
      css={css({
        height: ['auto', heightAdjust ? `calc(100vh - ${heightAdjust})` : '100vh'],
        bg: 'Gold',
        p: 3,
        color: 'SaddleBrown',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      Welcome
    </div>
  )
}

type PageContext = any // eslint-disable-line @typescript-eslint/no-explicit-any

type SiteMetadata = {
  siteTitle: string
  siteDescription: string
  siteKeywords: string[]
  copyright: string
}

const Layout = ({
  pageContext,
  siteMetadata,
  children,
}: {
  pageContext: PageContext
  siteMetadata: SiteMetadata
  children?: React.ReactNode
}) => {
  const hasHeader = false

  return (
    <GlobalProviders>
      <Helmet
        title={siteMetadata.siteTitle}
        meta={[
          { name: 'description', content: siteMetadata.siteDescription },
          { name: 'keywords', content: siteMetadata.siteKeywords.join(', ') },
        ]}
      />

      {pageContext.layout === 'homepage' ? <HomeHeader /> : null}

      {/* primary viewport */}
      <div
        css={{
          position: 'relative', // ?
        }}
      >
        <div
          css={css({
            display: ['block', 'flex'],
            flexDirection: 'row',
            minHeight: [undefined, undefined, '100vh'],
          })}
        >
          {/* sidebar */}
          <div
            css={css({
              width: ['auto', '33%'],
              minWidth: '16em',

              display: 'flex',
              flexDirection: 'column',
            })}
          >
            <div
              css={css({
                padding: [3, undefined, 4],
                position: ['static', 'sticky'],
                top: hasHeader ? '10em' : 0,
                left: 0,
              })}
            >
              <PrimaryNavigation />
            </div>
          </div>

          {/* main body */}
          <main id="main" css={css({ width: '100%', pb: [3, undefined, 7] })}>
            {children}
          </main>
        </div>
      </div>

      <div
        css={css({
          minHeight: [undefined, undefined, '100vh'],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bg: 'teal',
        })}
      >
        <Footer copyright={siteMetadata.copyright} />
      </div>
    </GlobalProviders>
  )
}

const LayoutContainer = (props: { pageContext: PageContext }) => {
  const siteMetadata = useSiteMetadata()
  return <Layout siteMetadata={siteMetadata} {...props} />
}

export default LayoutContainer
