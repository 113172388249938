import styled from '@emotion/styled'
import { Config, ResponsiveValue, system } from 'styled-system'
import { makeUsableStyledSystem } from 'use/design'
import { Base } from '../system'

const defaultScale = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const itemSpaceConfig: Config = {
  itemM: {
    property: 'margin',
    scale: 'space',
    defaultScale,
  },
  itemMt: {
    property: 'marginTop',
    scale: 'space',
    defaultScale,
  },
  itemMr: {
    property: 'marginRight',
    scale: 'space',
    defaultScale,
  },
  itemMb: {
    property: 'marginBottom',
    scale: 'space',
    defaultScale,
  },
  itemMl: {
    property: 'marginLeft',
    scale: 'space',
    defaultScale,
  },
  itemMx: {
    properties: ['marginRight', 'marginLeft'],
    scale: 'space',
    defaultScale,
  },
  itemMy: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    defaultScale,
  },
  itemP: {
    property: 'padding',
    scale: 'space',
    defaultScale,
  },
  itemPt: {
    property: 'paddingTop',
    scale: 'space',
    defaultScale,
  },
  itemPr: {
    property: 'paddingRight',
    scale: 'space',
    defaultScale,
  },
  itemPb: {
    property: 'paddingBottom',
    scale: 'space',
    defaultScale,
  },
  itemPl: {
    property: 'paddingLeft',
    scale: 'space',
    defaultScale,
  },
  itemPx: {
    properties: ['paddingRight', 'paddingLeft'],
    scale: 'space',
    defaultScale,
  },
  itemPy: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    defaultScale,
  },
}

interface Props {
  itemM?: ResponsiveValue<string | number>
  itemP?: ResponsiveValue<string | number>
  itemMt?: ResponsiveValue<string | number>
  itemMr?: ResponsiveValue<string | number>
  itemMb?: ResponsiveValue<string | number>
  itemMl?: ResponsiveValue<string | number>
  itemMx?: ResponsiveValue<string | number>
  itemMy?: ResponsiveValue<string | number>
  itemPt?: ResponsiveValue<string | number>
  itemPr?: ResponsiveValue<string | number>
  itemPb?: ResponsiveValue<string | number>
  itemPl?: ResponsiveValue<string | number>
  itemPx?: ResponsiveValue<string | number>
  itemPy?: ResponsiveValue<string | number>
}

const itemSpace = system(itemSpaceConfig)

export const SpaceItemsItem = styled(Base)``

// const BaseItems = styledMixinComponent<Props>(itemSpace)

export const SpaceItems = makeUsableStyledSystem<Props>(
  props => ({
    [`& ${SpaceItemsItem}`]: itemSpace(props),
  }),
  itemSpace.propNames
)

// SpaceItems.Item = SpaceItemsItem

export default SpaceItems

export const OwlSpaceItems = makeUsableStyledSystem<Props>(
  props => ({
    [`& ${SpaceItemsItem} + ${SpaceItemsItem}`]: itemSpace(props),
  }),
  itemSpace.propNames
)

// OwlSpaceItems.Item = SpaceItemsItem
