import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import css from '@styled-system/css'

import { panel } from '../design'
import { Text, Box } from '../use'
import { Stack } from '../design'

const AvatarImg = styled(Img)`
  border-radius: 9999px;
  background-color: #eee;
  max-width: 500px;
  width: 12em;
  margin-left: auto;
  margin-right: auto;
`

interface Entertainer {
  name: string
  strapline: string
  body: string
  avatarImageSizes?: FluidObject
  avatarImageAlt: string
}

const EntertainerProfile = ({
  name,
  strapline,
  body,
  avatarImageSizes,
  avatarImageAlt,
}: Entertainer) => (
  <section
    css={css({
      '&': {
        fontSize: 3,
      },
    })}
  >
    <Stack>
      {avatarImageSizes ? (
        <Box my={3}>
          <AvatarImg sizes={avatarImageSizes} alt={avatarImageAlt} />
        </Box>
      ) : null}
      <Text use="h3" fontSize="2.6rem" fontWeight={400} lineHeight={1} textAlign="center">
        {name}
      </Text>
      <Text
        use="em"
        display="block"
        fontStyle="normal"
        fontWeight={500}
        fontSize={4}
        textAlign="center"
        maxWidth="24em"
        mx="auto"
      >
        {strapline}
      </Text>
      <MDXRenderer>{body}</MDXRenderer>
    </Stack>
  </section>
)

const EntertainerList = ({ entertainers }: { entertainers: Entertainer[] }) => (
  <ul css={css({ listStyle: 'none', p: 'none', '> li + li': { mt: 3 } })}>
    {entertainers.map(item => (
      <li key={item.name} css={panel}>
        <EntertainerProfile {...item} />
      </li>
    ))}
  </ul>
)

const entertainersQuery = graphql`
  query {
    entertainers: allEntertainer(sort: { fields: [sort], order: ASC }) {
      edges {
        node {
          markdown {
            body
            frontmatter {
              title
              strapline
              avatar {
                childImageSharp {
                  fluid(maxWidth: 500, maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              avatarAlt
            }
          }
        }
      }
    }
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapEntertainersData = (data: any): Entertainer[] =>
  data.entertainers.edges.map((edge: any) => ({
    name: edge.node.markdown.frontmatter.title,
    strapline: edge.node.markdown.frontmatter.strapline,
    body: edge.node.markdown.body,
    avatarImageSizes: edge.node.markdown.frontmatter.avatar
      ? edge.node.markdown.frontmatter.avatar.childImageSharp.fluid
      : null,
    avatarImageAlt: edge.node.markdown.frontmatter.avatarAlt,
  }))
/* eslint-enable @typescript-eslint/no-explicit-any */

const useEntertainers = () => {
  const data = useStaticQuery(entertainersQuery)
  return mapEntertainersData(data)
}

const EntertainerListContainer = (props: {}) => {
  const entertainers = useEntertainers()
  return <EntertainerList entertainers={entertainers} {...props} />
}

export default EntertainerListContainer
