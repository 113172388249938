import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'

export const servicesQuery = graphql`
  query {
    services: allService(sort: { fields: [sort], order: ASC }) {
      edges {
        node {
          page {
            path
          }
          markdown {
            frontmatter {
              name
              image {
                alt
                file {
                  thumb: childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface ServicesQueryResult {
  services: {
    edges: Array<
      [
        {
          node: {
            page: {
              path: string
            }
            markdown: {
              frontmatter: {
                name: string
                image: {
                  alt: string
                  file: {
                    thumb: {
                      fluid: FluidObject
                    }
                  }
                }
              }
            }
          }
        }
      ]
    >
  }
}

const useServicePages = () => {
  const data = useStaticQuery<ServicesQueryResult>(servicesQuery)
  return data.services
}

export default useServicePages
