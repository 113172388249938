import { useServicePages } from 'queries';
import SecondaryNavigation, { mapPagesToItems } from 'components/SecondaryNavigation';
import { BreadcrumbSchema } from 'components/JsonLd';
import { Box } from 'use';
import * as React from 'react';
export default {
  useServicePages,
  SecondaryNavigation,
  mapPagesToItems,
  BreadcrumbSchema,
  Box,
  React
};