import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import css from '@styled-system/css'

import { Box } from '../use'

const SecondaryNavigation = ({ items, ...props }) => (
  <Box as="nav" {...props}>
    <ul
      css={css({
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
      })}
    >
      {items &&
        items.length &&
        items.map(item => (
          <li key={item.path}>
            <Link
              to={item.path}
              css={css({
                display: 'block',
                mr: 4,
                my: 3,
                fontSize: [2, undefined, 3],
                '&[aria-current]': {
                  fontWeight: 'bold',
                },
              })}
            >
              {item.name}
            </Link>
          </li>
        ))}
    </ul>
  </Box>
)

SecondaryNavigation.propTypes = {
  ...Box.propTypes,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SecondaryNavigation

export const mapPagesToItems = pages =>
  pages.edges.map(edge => ({
    path: edge.node.page.path,
    name: edge.node.markdown.frontmatter.name,
  }))
