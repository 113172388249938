import { graphql, useStaticQuery } from 'gatsby'

export const reviewsQuery = graphql`
  fragment ReviewContent on ReviewsYaml {
    id
    date
    authorName
    review
    rating
  }

  fragment ReviewMeta on ReviewsYaml {
    source
    sourceUrl
    facebookId
  }

  query {
    reviews: allReviewsYaml(sort: { fields: [date], order: DESC }, limit: 50) {
      edges {
        node {
          ...ReviewContent
          ...ReviewMeta
        }
      }
    }
  }
`

interface ReviewContent {
  id: string
  date: string
  authorName: string
  review: string
  rating: number
}

interface ReviewMeta {
  source: 'facebook' | 'netmums'
  sourceUrl?: string
  facebookId?: string
}

interface Review extends ReviewContent, ReviewMeta {}

interface ReviewsQueryResult {
  reviews: {
    edges: Array<{
      node: Review
    }>
  }
}

const useReviewsData = () => {
  const data = useStaticQuery<ReviewsQueryResult>(reviewsQuery)
  return data.reviews
}

export default useReviewsData
