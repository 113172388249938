import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import use from 'reuse'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { panel } from '../design'
import { Div, CaptureLink } from '../use'

const hoverStyles = css`
  & {
    will-change: transform, background-color, color;
    transition: all ease 200ms;
  }

  &:hover {
    background-color: blue;
    color: white;
    transform: translateY(-0.25em);
  }
`

const Root = styled(Div)`
  ${panel};
  ${hoverStyles}
  display: block;
  max-width: 32rem;
`

const ContactCta = use(({ title, body, action, to, use: _use, ...props }) => (
  <Root use={[CaptureLink, _use]} {...props}>
    <h4>{title}</h4>
    <p>{body}</p>
    <Link to={to}>{action}</Link>
  </Root>
))

ContactCta.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  action: PropTypes.node,
  to: PropTypes.string,
  use: PropTypes.string,
  ...CaptureLink.propTypes,
}

ContactCta.defaultProps = {
  title: 'Talk to a Party Advisor for a quote for the where your party is located',
  body:
    'With years of experience party planning, we can help make sure you have the best entertainment for your age group and event',
  action: 'Talk to a party advisor',
  to: '/contact/',
  use: 'div',
}

export default ContactCta
