import React from 'react'
import { Provider as ReakitProvider } from 'reakit'
import * as system from 'reakit-system-bootstrap'
import { MDXProvider } from '@mdx-js/react'
import { ThemeProvider } from '@emotion/react'

import * as components from '../use/mdx-components'
import { LocationProvider } from '../hooks'
import { theme } from '../design'
import { GlobalStyles } from '../design'

const GlobalProviders = ({ children }: { children: React.ReactNode }) => (
  <ReakitProvider unstable_system={system}>
    <ThemeProvider theme={theme}>
      <MDXProvider components={components as any}>
        <LocationProvider>
          <>
            <GlobalStyles />
            {children}
          </>
        </LocationProvider>
      </MDXProvider>
    </ThemeProvider>
  </ReakitProvider>
)

export default GlobalProviders
