// example fontSizes scale as an array
interface FontSizes {
  [position: number]: number
  body: number
  display: number
}

interface Space {
  [position: number]: number
  small: number
  medium: number
  large: number
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const fontSizes: FontSizes = [12, 14, 16, 20, 24, 32]
fontSizes.body = fontSizes[2]
fontSizes.display = fontSizes[5]

const colors = {
  blue: '#07c',
  blues: ['#004170', '#006fbe', '#2d8fd5', '#5aa7de'],
}

const breakpoints = ['40em', '52em', '64em']

const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const space: Space = [0, 4, 8, 16, 32]
space.small = space[1]
space.medium = space[2]
space.large = space[3]

export const theme = {
  fontSizes,
  colors,
  breakpoints,
  mediaQueries,
  space,
}

export default theme
