import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Base } from '../system'

// * + *

const OwlSpaceItem = styled(Base)``

export const OwlSpace = styled(({ gutter, ...props }) => <Base {...props} />)`
  ${({ gutter }) =>
    gutter &&
    `
    > ${OwlSpaceItem} + ${OwlSpaceItem} {
      margin-top: ${gutter};
    }
  `}
`
OwlSpace.propTypes = {
  gutter: PropTypes.string,
}

OwlSpace.Item = OwlSpaceItem

export default OwlSpace
