import React from 'react'
import { format } from 'date-fns'

const lastUpdated = new Date('2018-05-01')

interface ReviewScoreboardProps {
  reviewUrls: {
    facebook: string
    freeindex: string
    yell: string
  }
}

const ReviewScoreboard = ({ reviewUrls }: ReviewScoreboardProps) => (
  <section>
    <h2>Review score board</h2>
    <p>
      <small>
        Last updated{' '}
        <time dateTime={format(lastUpdated, 'yyyy-MM-dd')}>
          {format(lastUpdated, 'Do MMM yyyy')}
        </time>
      </small>
    </p>

    <h3>Facebook reviews</h3>
    <p>5.0 of 5 stars from 77 reviews</p>
    <p>
      <a href={reviewUrls.facebook} rel="nofollow noopener noreferrer">
        Read our Facebook reviews
      </a>
    </p>

    <h3>FreeIndex reviews</h3>
    <p>4.8 of 5 stars from 139 reviews</p>
    <p>
      <a href={reviewUrls.freeindex} rel="nofollow noopener noreferrer">
        Read our FreeIndex reviews
      </a>
    </p>

    <h3>Yell reviews</h3>
    <p>5.0 of 5 stars from 3 reviews</p>
    <p>
      <a href={reviewUrls.yell} rel="nofollow noopener noreferrer">
        Read our Yell reviews
      </a>
    </p>
  </section>
)

export default ReviewScoreboard
