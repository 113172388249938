import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const IndexList = ({ items }) => (
  <ul>
    {items.map(item => (
      <li key={item.path}>
        <Link to={item.path}>{item.name}</Link>
      </li>
    ))}
  </ul>
)

IndexList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default IndexList

export const mapPagesToItems = pages =>
  pages.edges.map(edge => ({
    path: edge.node.page.path,
    name: edge.node.markdown.frontmatter.name || edge.node.markdown.frontmatter.title,
  }))
