import React from 'react'
import PropTypes from 'prop-types'

import Navigation from '../Navigation'

const Footer = ({ copyright, ...props }) => (
  <footer>
    <Navigation {...props} />
    <p>{copyright}</p>
  </footer>
)

Footer.propTypes = {
  copyright: PropTypes.string.isRequired,
}

export default Footer
