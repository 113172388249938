import { css, CSSObject } from '@emotion/react'
import * as CSS from 'csstype'
import { compose, styleFn, system } from 'styled-system'
import { makeUsableStyledSystem } from '../../use/design'
import { themeSpaceToPx } from '../utils'

// <Cluster>
// https://absolutely.every-layout.dev/layouts/cluster/

interface ClusterProps {
  space?: string | number | (string | number)[]
  justify?: CSSObject['justifyContent']
  align?: CSSObject['alignItems']
}

const varSystem = system({
  space: {
    property: '--cluster-space' as keyof CSS.Properties,
    scale: 'space',
    transform: themeSpaceToPx,
  },
})

const layoutSystem: styleFn = (props: ClusterProps) =>
  css({
    '> *': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: props.justify,
      alignItems: props.align,
      margin: 'calc(var(--cluster-space) / 2 * -1)',
    },
    '> * > *': {
      margin: 'calc(var(--cluster-space) / 2)',
    },
  })
layoutSystem.propNames = ['justify', 'align']
layoutSystem.config = { justify: true, align: true }

const clusterSystem = compose(varSystem, layoutSystem)

export const Cluster = makeUsableStyledSystem<ClusterProps>(clusterSystem)

Cluster.defaultProps = {
  space: '1rem',
  justify: 'flex-start',
  align: 'center',
}

// const a = <Cluster  use="a"  href="" align="baseline" justify="end" />
// const a = <Div use={[Cluster, 'a']} href="" align="baseline" justify="end" />
