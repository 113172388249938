import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const faqsQuery = graphql`
  query {
    faqs: allFaqsYaml {
      edges {
        node {
          q
          a
        }
      }
    }
  }
`

const useFaqs = () => {
  const data = useStaticQuery(faqsQuery)
  const faqs = useMemo(
    () =>
      data.faqs.edges.map(({ node: { q: question, a: answer } }) => ({
        question,
        answer,
      })),
    [data]
  )
  return faqs
}

const Faqs = React.memo(() => {
  const faqs = useFaqs()
  return (
    <dl>
      {faqs.map((faq, i) => (
        <React.Fragment key={i}>
          <dt>{faq.question}</dt>
          <dd>{faq.answer}</dd>
        </React.Fragment>
      ))}
    </dl>
  )
})

Faqs.displayName = 'Faqs'

export default Faqs
