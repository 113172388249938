import styled from '@emotion/styled'
import omit from 'lodash/omit'
import React from 'react'
import use from 'reuse'
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  styleFn,
  typography,
  TypographyProps,
} from 'styled-system'
import { Base } from './system'

// styled-system primatives

// mixins

export const makeUsableStyledSystem = <Props extends {}>(
  mixin: styleFn,
  propNames = mixin.propNames || []
) => {
  const Clean: React.ComponentType<Props & { children?: React.ReactNode }> = props => (
    <Base {...omit(props, propNames)} />
  )

  const Styled = styled(Clean)(mixin) as React.FC<Props & { children?: React.ReactNode }>

  return use(Styled, 'div')
}

export const Space = makeUsableStyledSystem<SpaceProps>(space)
export const Color = makeUsableStyledSystem<ColorProps>(color)
export const Typography = makeUsableStyledSystem<TypographyProps>(typography)
export const Layout = makeUsableStyledSystem<LayoutProps>(layout)
export const Flex = makeUsableStyledSystem<FlexboxProps>(flexbox)
export const Grid = makeUsableStyledSystem<GridProps>(grid)
export const Background = makeUsableStyledSystem<BackgroundProps>(background)
export const Border = makeUsableStyledSystem<BorderProps>(border)
export const Position = makeUsableStyledSystem<PositionProps>(position)
export const Shadow = makeUsableStyledSystem<ShadowProps>(shadow)

// const a = <Div use={[Space]} mt={1} banana />
// const b = <Space mt={1} marginBottom={false} />
// const c = <Space use={Position} position="static" marginBottom={null} />

// styled-system tags

export const Box = makeUsableStyledSystem<SpaceProps & ColorProps & LayoutProps>(
  compose(space, color, layout)
)
export const Text = makeUsableStyledSystem<TypographyProps & SpaceProps & ColorProps & LayoutProps>(
  compose(typography, space, color, layout)
)
export const SuperBox = makeUsableStyledSystem<
  FlexboxProps & SpaceProps & ColorProps & LayoutProps
>(compose(flexbox, space, color, layout))
