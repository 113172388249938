import { useSiteMetadata, useReviewsData } from 'queries';
import ReviewScoreboard from 'components/ReviewScoreboard';
import ReviewsList from 'components/ReviewsList';
import * as React from 'react';
export default {
  useSiteMetadata,
  useReviewsData,
  ReviewScoreboard,
  ReviewsList,
  React
};