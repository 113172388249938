import { useLocationPages } from 'queries';
import IndexList, { mapPagesToItems } from 'components/IndexList';
import { BreadcrumbSchema } from 'components/JsonLd';
import * as React from 'react';
export default {
  useLocationPages,
  IndexList,
  mapPagesToItems,
  BreadcrumbSchema,
  React
};