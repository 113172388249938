/* eslint-disable no-underscore-dangle */

import React from 'react'
import PropTypes from 'prop-types'
import { compose, setPropTypes, withProps, setDisplayName } from 'recompose'

const _address = {
  '@type': 'PostalAddress',
  streetAddress: '35 Papenburg Road',
  addressLocality: 'Canvey Island',
  addressRegion: 'Essex',
  postalCode: 'SS8 9NZ',
  addressCountry: 'GB',
}

const _areaServed = {
  '@type': 'GeoShape',
  polygon:
    '-0.004348693412230542,51.77208732507592,0 -0.03256798450916532,51.70152450767671,0 -0.01754530920563213,51.62121888504232,0 0.07304787714158989,51.52432939875735,0 0.1465868013694527,51.4799709461768,0 0.1676496710838848,51.47744337376646,0 0.1855191557959857,51.46863842285055,0 0.2233770002971225,51.42244297670567,0 0.3135533739358665,51.40743991917643,0 0.4347909041371678,51.45081204742403,0 0.4673161742007026,51.4940814333544,0 0.6179871288122363,51.49196891883866,0 0.8488252999811552,51.48391293819382,0 1.007336940146886,51.60260708175004,0 1.012522054432119,51.6082995127794,0 0.9903769540045215,51.73412671207635,0 1.00393791608953,51.75184649006747,0 1.108606172318385,51.74117870791356,0 1.201687194427716,51.77052156864203,0 0.9943089343045752,51.87609026872344,0 0.9190787501974484,51.90957110832964,0 0.56811746068822,51.91473548506721,0 0.3772244999550778,51.91026116831527,0 0.1362276258764994,51.89388244397266,0 -0.004348693412230542,51.77208732507592,0',
}

const _memberOf = [
  {
    '@type': 'Organization',
    name: 'The International Brotherhood of Magicians',
    alternateName: 'IBM',
    sameAs: 'https://www.magician.org',
    description: 'Organization dedicated to the art of magic',
  },
  {
    '@type': 'Organization',
    name: 'The Magic Circle',
    sameAs: 'https://themagiccircle.co.uk',
    description: 'Global magical society',
  },
  {
    '@type': 'Organization',
    name: 'Equity',
    sameAs: 'https://www.equity.org.uk',
    description: 'Performer’s trade union',
  },
  {
    '@type': 'Organization',
    name: 'Ilford Magical Society',
    sameAs: 'http://www.ilfordmagic.co.uk',
    description: 'Local magical society',
  },
]

const _openingHours = 'Mo-Sa 09:00-18:00; Su 10:00-16:00'

// const _hoursAvalible = [
//   {
//     "@type": "OpeningHoursSpecification",
//     "dayOfWeek": [
//       "http://schema.org/Monday",
//       "http://schema.org/Tuesday",
//       "http://schema.org/Wednesday",
//       "http://schema.org/Thursday",
//       "http://schema.org/Friday",
//       "http://schema.org/Saturday",
//     ],
//     "opens": "09:00:00",
//     "closes": "18:00:00",
//   },
//   // bug: Google Structured Data tool errors with multipul opens/closes on hoursAvailable
//   // {
//   //   "@type": "OpeningHoursSpecification",
//   //   "dayOfWeek": "http://schema.org/Sunday",
//   //   "opens":  "10:00:00",
//   //   "closes":  "16:00:00",
//   // },
// ]

function precisionRound(number, precision) {
  const factor = 10 ** precision
  return Math.round(number * factor) / factor
}

const JsonLd = ({ data }) => (
  /* eslint-disable react/no-danger */
  <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
)

JsonLd.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.object.isRequired,
}

export default JsonLd

export const OrgSchema = compose(
  setDisplayName('OrgSchema'),
  setPropTypes({
    orgName: PropTypes.string.isRequired,
    orgFoundingDate: PropTypes.string.isRequired,
    orgSameAs: PropTypes.arrayOf(PropTypes.string).isRequired,
    siteUrl: PropTypes.string.isRequired,
    siteLogo: PropTypes.string.isRequired,
    contactPhone: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
  }),
  withProps(
    ({
      orgName,
      orgDescription,
      orgMapUrl,
      orgLogo,
      orgImage,
      orgFoundingDate,
      orgSameAs,
      siteUrl,
      contactEmail,
      contactPhone,
    }) => ({
      data: {
        '@id': '/#business',
        '@context': 'http://schema.org',
        '@type': 'EntertainmentBusiness',
        name: orgName,
        description: orgDescription,
        url: siteUrl,
        logo: orgLogo,
        image: orgImage,
        email: contactEmail,
        telephone: contactPhone,
        address: _address,
        areaServed: _areaServed,
        foundingDate: orgFoundingDate,
        openingHours: _openingHours,
        hasMap: orgMapUrl,
        memberOf: _memberOf,
        sameAs: orgSameAs,
        priceRange: '££',
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '51.532878',
          longitude: '0.582629',
        },
        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            telephone: contactPhone,
            email: contactEmail,
            hoursAvailable: _openingHours,
          },
        ],
        aggregateRating: {
          ratingValue: '5',
          reviewCount: '10',
        },
      },
    })
  )
)(JsonLd)

export const ServiceSchema = compose(
  setDisplayName('ServiceSchema'),
  setPropTypes({
    siteUrl: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    alternateName: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    description: PropTypes.string.isRequired,
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
      })
    ),
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.number.isRequired,
        body: PropTypes.string.isRequired,
        authorName: PropTypes.string.isRequired,
        authorDescription: PropTypes.string.isRequired,
      })
    ),
  }),
  withProps(({ siteUrl, path, alternateName, name, image, description, ratings, reviews }) => ({
    data: {
      '@id': path,
      '@context': 'http://schema.org',
      '@type': 'Service',
      name,
      areaServed: _areaServed,
      alternateName,
      termsOfService: `${siteUrl}/terms`,
      ...(image && { image: Array.isArray(image) ? image : [image] }),
      description,
      ...(ratings &&
        ratings.length && {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: precisionRound(
              ratings.reduce((acc, { value }) => acc + value, 0) / ratings.length,
              2
            ),
            ratingCount: ratings.length,
            reviewCount: reviews.length,
            bestRating: 5,
            worstRating: 1,
          },
        }),
      ...(reviews &&
        reviews.length && {
          review: reviews.map(review => ({
            reviewBody: review.body,
            reviewRating: {
              '@type': 'Rating',
              ratingValue: review.rating,
              bestRating: 5,
              worstRating: 0,
            },
            author: {
              '@type': 'Person',
              name: review.authorName,
              description: review.authorDescription,
            },
          })),
        }),
      audience: {
        '@type': 'Audience',
        audienceType: 'children',
        geographicArea: {
          '@type': 'AdministrativeArea',
          geo: _areaServed,
        },
      },
      brand: {
        '@id': '/#business',
        '@type': 'Organization',
      },
      provider: {
        '@id': '/#business',
        '@type': 'Organization',
      },
    },
  }))
)(JsonLd)

export const breadcrumbsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
)

export const BreadcrumbSchema = compose(
  setDisplayName('BreadcrumbSchema'),
  setPropTypes({
    items: breadcrumbsPropType.isRequired,
  }),
  withProps(({ items }) => ({
    data: {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items.map(({ path, name }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        item: {
          '@id': path,
          name,
        },
      })),
    },
  }))
)(JsonLd)

// export const ArticleSchema = compose(
//   setPropTypes({
//     title: PropTypes.string.isRequired,
//     date: PropTypes.string.isRequired,
//     authorName: PropTypes.string.isRequired,
//     imageUrl: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//   }),
//   withProps(({ title, date, authorName, imageUrl, description }) => ({
//     data: {
//       "@context": "http://schema.org/",
//       "@type": "BlogPosting",
//       "author": {
//         "@type": "Person",
//         "name": authorName,
//       },
//       "headline": title,
//       "datePublished": new Date(date).toISOString(),
//       "image": imageUrl,
//       "description": description,
//     },
//   })),
// )(JsonLd)

// export const ReviewSchema = compose(
//   setPropTypes({
//   }),
//   withProps(({ siteUrl, alternateName, name, image, description, offers }) => ({
//     data: {
//       "@context": "http://schema.org/",
//       "@type": "Review",
//       "itemReviewed": {
//         "name": itemName,
//         "sameAs": itemUrl,
//         "address": {
//           "@type": "PostalAddress",
//           "streetAddress": "35 Papenburg Road",
//           "addressLocality": "Canvey Island",
//           "addressRegion": "Essex",
//           "postalCode": "SS8 9NZ",
//           "addressCountry": "GB",
//           "geo": {
//             "@type": "GeoCoordinates",
//             "latitude": "",
//             "longitude": "",
//           },
//           "telephone": contactPhone,
//         },
//         "author": {
//           "@type": "Person",
//           "name": authorName,
//           "sameAs": authorUrl,
//         },
//       },
//       "datePublished": datePublished,
//       "description": description,
//       "inLanguage": "en",
//       "publisher": {
//         "@type": "Organization",
//         "name": orgName,
//         "sameAs": orgUrl,
//       },
//       "reviewRating": {
//         "@type": "Rating",
//         "ratingValue": String(ratingValue.round(0)),
//         "bestRating": String(bestRating.round()),
//         "worstRating": String(worstRating.round()),
//       },
//     },
//   })),
// )(JsonLd)
