import { graphql, useStaticQuery } from 'gatsby'

export const paraphrasesQuery = graphql`
  query {
    paraphrases: allReviewsYaml(sort: { fields: [date], order: DESC }, limit: 10) {
      edges {
        node {
          id
          paraphrases
          authorName
        }
      }
    }
  }
`

const useReviewParaphrasesData = () => {
  const data = useStaticQuery(paraphrasesQuery)

  const paraphrases = data.paraphrases.edges.reduce(
    (acc, { node }) =>
      acc.concat(
        node.paraphrases.map(body => ({
          body,
          review: node,
        }))
      ),
    []
  )

  return paraphrases
}

export default useReviewParaphrasesData
