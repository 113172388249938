import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { useMounted } from '../../hooks'
import { Div } from '../system'

const handleCapturedLinkClick = event => {
  const linkElement = event.currentTarget.querySelector('a[href]')
  if (linkElement) return navigate(linkElement.href)
  return undefined
}

export const CaptureLink = ({ onClick, ...props }) => {
  const handleClick = useCallback(
    event => {
      if (onClick) {
        const onClickReturn = onClick(event)
        if (onClickReturn === false) return false
      }
      return handleCapturedLinkClick(event)
    },
    [onClick]
  )

  const mounted = useMounted()

  return (
    <Div
      css={{
        cursor: mounted ? 'pointer' : 'default',
      }}
      onClick={handleClick}
      {...props}
    />
  )
}

CaptureLink.propTypes = {
  onClick: PropTypes.func,
}

CaptureLink.defaultProps = {
  onClick: undefined,
}

export default CaptureLink
