import React from 'react'
import { Link } from 'gatsby'

import { Base, OwlSpaceItems, SpaceItemsItem, Typography } from '../../../use'

// Note: emotion as prop not typed correctly
const List = (props: React.ComponentProps<typeof SpaceItemsItem>) => (
  <OwlSpaceItems use="ul" itemMt={[3, null, null, 4]} css={{ listStyle: 'none', marginTop: 0 }} {...props} />
)

const ListItem = (props: React.ComponentProps<typeof Link>) => (
  <Base use={[SpaceItemsItem, Typography, 'li']} fontSize={[1, 3]}>
    <Base use={Link} {...props} />
  </Base>
)

const Nav = () => (
  <nav>
    <List>
      <ListItem to="/">Professor Rainbows</ListItem>
      <ListItem to="/services/">Services</ListItem>
      <ListItem to="/locations/">Locations</ListItem>
      <ListItem to="/entertainers/">Entertainers</ListItem>
      <ListItem to="/contact/">Contact</ListItem>
      <ListItem to="/about/">About</ListItem>
    </List>
  </nav>
)

export default Nav
