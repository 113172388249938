import { css } from '@emotion/react'

const sizes = {
  phone: 0,
  tablet: 376,
  desktop: 992,
  giant: 1170,
}

const media = Object.entries(sizes).reduce((acc, [label, size]) => {
  const emSize = size / 16

  acc[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export default media
