import { css } from '@emotion/react'
import * as CSS from 'csstype'
import { compose, styleFn, system } from 'styled-system'
import { makeUsableStyledSystem } from '../../use/design'
import { themeSpaceToPx } from '../utils'

// <Stack>
// https://absolutely.every-layout.dev/layouts/stack/

interface StackProps {
  space?: string | number | (string | number)[]
  recursive?: boolean
  // splitAfter?: number // todo: add splitAfter support
}

const variables = system({
  space: {
    property: '--stack-space' as keyof CSS.Properties,
    scale: 'space',
    transform: themeSpaceToPx,
  },
})

const layout: styleFn = (props: StackProps) =>
  css({
    '> *': {
      marginTop: 0,
      marginBottom: 0,
    },
    [props.recursive ? '* + *' : '> * + *']: {
      marginTop: 'var(--stack-space)',
    },
  })
layout.propNames = ['recursive']
layout.config = { recursive: true }

const stackSystem = compose(variables, layout)

export const Stack = makeUsableStyledSystem<StackProps>(stackSystem)

Stack.defaultProps = {
  space: 3,
}
