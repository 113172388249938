import React from 'react'
import styled from '@emotion/styled'
import use from 'reuse'
import { space } from 'styled-system'

const classnames = (...args) => args.join(' ')
const getClassName = el => (el.props && el.props.className) || ''

// eslint-disable-next-line
const StyledChildren = ({ className, children, ...props }) => {
  const styledChildren = React.Children.toArray(children).map(child =>
    React.cloneElement(child, {
      ...props,
      className: classnames(getClassName(child), className),
    })
  )
  return <>{styledChildren}</>
}

export const SpaceChildren = styled(use(StyledChildren))(space)

SpaceChildren.propTypes = space.propTypes

export default SpaceChildren
