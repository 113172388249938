import React from 'react'
import use from 'reuse'

// base component

const renderBase: React.FC<{ use: React.ComponentType }> = ({ use: Cmp, ...props }) => (
  <Cmp {...props} />
)

// elements

export const Base = use(renderBase as React.FC<{ children?: React.ReactNode }>)

export const Div = use(Base, 'div')
