import { Scale } from 'styled-system'

export const themeSpaceToPx = (value: number | string | object, scale?: Scale) => {
  if (typeof value === 'number' && Array.isArray(scale) && scale[value]) {
    return scale[value] + 'px'
  }
  if (
    typeof value === 'string' &&
    typeof scale === 'object' &&
    (scale as Record<string, string>)[value]
  ) {
    return (scale as Record<string, string>)[value] + 'px'
  }

  return value
}
