import React from 'react'
import 'typeface-rubik'
import { css, Global } from '@emotion/react'
// import normalizeStyles from 'styled-normalize'

export { default as media } from './media'
export * from './theme'
export * from './layout'

const systemFontStack =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const globalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: Rubik, ${systemFontStack};
    text-rendering: optimizeLegibility;
  }

  /* 100% height layout */
  html,
  body,
  #root {
    height: 100%;
    margin: 0;
  }

  /* Scrollbar */
  body::-webkit-scrollbar {
    width: 1.2em;
    /* background: red; */
  }
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgba(100, 100, 255, 1);
  }
  body::-webkit-scrollbar-thumb {
    background: rgba(100, 200, 255, 1);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  p,
  li {
    max-width: 42em;
  }

  body {
    line-height: 1.5;
  }
`

export const GlobalStyles = () => <Global styles={globalStyles} />

// styles
// 'styles'

export const panel = css`
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.2);
`

export const vh = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`
