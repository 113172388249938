import { graphql, useStaticQuery } from 'gatsby'

const siteMetadataQuery = graphql`
  query {
    site {
      siteMetadata {
        siteTitle
        siteTitleShort
        siteDescription
        siteKeywords
        siteUrl
        siteLogo
        copyright
        facebookAppId
        twitterUser

        reviewUrls {
          facebook
          netmums
          yell
          freeindex
        }

        orgName
        orgDescription
        orgFoundingDate
        orgSameAs
        orgMapUrl
        orgLogo
        orgImage

        contactEmail
        contactPhone
      }
    }
  }
`

const useSiteMetadata = () => {
  const data = useStaticQuery(siteMetadataQuery)
  return data.site.siteMetadata
}

export default useSiteMetadata
