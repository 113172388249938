// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-locations-basildon-mdx": () => import("./../../../src/pages/new-locations/basildon.mdx" /* webpackChunkName: "component---src-pages-new-locations-basildon-mdx" */),
  "component---src-pages-new-locations-benfleet-mdx": () => import("./../../../src/pages/new-locations/benfleet.mdx" /* webpackChunkName: "component---src-pages-new-locations-benfleet-mdx" */),
  "component---src-pages-new-locations-canvey-island-mdx": () => import("./../../../src/pages/new-locations/canvey-island.mdx" /* webpackChunkName: "component---src-pages-new-locations-canvey-island-mdx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

