import { graphql, useStaticQuery } from 'gatsby'

export const locationsQuery = graphql`
  query {
    locations: allLocation {
      edges {
        node {
          page {
            path
          }
          markdown {
            frontmatter {
              title
              name
            }
          }
        }
      }
    }
  }
`

const useLocationPages = () => {
  const data = useStaticQuery(locationsQuery)
  return data.locations
}

export default useLocationPages
